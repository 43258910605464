import type { ReactNode } from 'react'
import { useCallback, useRef, useState } from 'react'
import { animated, useSpringValue } from '@react-spring/web'
import { clamp } from '@react-spring/shared'

import { useWindowResize } from '@hooks/useWindowResize'

import { DockContext } from './DockContext'

interface DockProps {
  children: ReactNode
}

export const DOCK_ZOOM_LIMIT = [-100, 50]

export function Dock({ children }: DockProps) {
  const [hovered, setHovered] = useState(false)
  const [width, setWidth] = useState(0)
  const isZooming = useRef(false)
  const dockRef = useRef<HTMLDivElement>(null!)

  const setIsZooming = useCallback((value: boolean) => {
    isZooming.current = value
    setHovered(!value)
  }, [])

  const zoomLevel = useSpringValue(1, {
    onChange: () => {
      setWidth(dockRef.current.clientWidth)
    }
  })

  useWindowResize(() => {
    setWidth(dockRef.current.clientWidth)
  })

  return (
    <DockContext.Provider value={{ hovered, setIsZooming, width, zoomLevel }}>
      <animated.div
        ref={dockRef}
        className=" flex flex-row justify-center items-center gap-12px mt-20px mb-36px min-h-98px <sm:mt-0px <sm:mb-40px transform <sm:!scale-80"
        onMouseOver={() => {
          if (!isZooming.current)
            setHovered(true)
        }}
        onMouseOut={() => {
          setHovered(false)
        }}
        style={{
          scale: zoomLevel
            .to({
              range: [-100, 1, 50],
              output: [2, 1, 0.5]
            })
            .to(value => clamp(0.5, 2, value))
        }}
      >
        {children}
      </animated.div>
    </DockContext.Provider>
  )
}

export function StaticDock({ children }: DockProps) {
  return <div
        className=" flex flex-row justify-center items-center gap-12px mt-20px mb-36px min-h-98px <sm:mt-0px <sm:mb-40px transform <sm:!scale-80"
      >
        {children}
      </div>
}
