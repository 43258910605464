import * as React from 'react'
import {
  animated,
  useIsomorphicLayoutEffect,
  useSpring,
  useSpringValue
} from '@react-spring/web'
import { useMousePosition } from '@hooks/useMousePosition'
import { useWindowResize } from '@hooks/useWindowResize'
import type { NavItem } from '@utils/constants'
import { useDock } from '../Dock/DockContext'

export function DockCard({ item }: { item: NavItem }) {
  const cardRef = React.useRef<HTMLAnchorElement>(null!)
  const [elCenterX, setElCenterX] = React.useState<number>(0)
  const pathname = globalThis?.window?.location?.pathname
  const isHomeCard = item.href === '/'
  const isActive = isHomeCard ? pathname === '/' : pathname?.includes(item.href)

  const containerSize = isActive ? 54 : 48
  const imageSize = isActive ? 35 : 30
  const INITIAL_WIDTH = containerSize
  const INITIAL_IMG_WIDTH = imageSize

  const size = useSpringValue(INITIAL_WIDTH, {
    config: {
      mass: 0.1,
      tension: 320
    }
  })
  const ImgSize = useSpringValue(INITIAL_IMG_WIDTH, {
    config: {
      mass: 0.1,
      tension: 320
    }
  })
  const y = useSpringValue(0, {
    config: {
      friction: 29,
      tension: 238
    }
  })
  const dock = useDock()

  useMousePosition(
    {
      onChange: ({ value }) => {
        const mouseX = value.x

        if (dock.width > 0) {
          const transformedValue
            = INITIAL_WIDTH
            + 20
            * Math.cos((((mouseX - elCenterX) / dock.width) * Math.PI) / 2)
            ** 120
          const transImgValue
            = INITIAL_IMG_WIDTH
            + 10
            * Math.cos((((mouseX - elCenterX) / dock.width) * Math.PI) / 2)
            ** 120

          if (dock.hovered) {
            size.start(transformedValue)
            ImgSize.start(transImgValue)
          }
        }
      }
    },
    [elCenterX, dock]
  )

  useIsomorphicLayoutEffect(() => {
    if (!dock.hovered) {
      size.start(INITIAL_WIDTH)
      ImgSize.start(INITIAL_IMG_WIDTH)
    }
  }, [dock.hovered])

  useWindowResize(() => {
    const { x } = cardRef.current.getBoundingClientRect()
    setElCenterX(x + INITIAL_WIDTH / 2)
  })
  const activeStyles = useSpring(
    {
      background: isActive
        ? 'linear-gradient(142.9deg, #2f4efa -30.01%, #8fffab 113.8%)'
        : 'white',
      show: isActive ? 'none' : 'block',
      hide: isActive ? 'block' : 'none',
      opacity: isActive ? '1' : '0'
    }
  )
  return (
    <animated.a
      ref={cardRef}
      href={item.href}
      className={` group relative bg-hex-fff rounded-13px shadow w-53px h-53px flex justify-center items-center <sm:rounded-8px 
      `}
      id={item.href}
      style={{
        background: activeStyles.background,
        width: size,
        height: size,
        y
      }}
    >
      {/* FIXME: weird issue on remove below p tag */}
      {/* <p className="hidden">
        {isActive
          ? 'true'
          : 'false'}
      </p> */}

      <animated.img
        src={`/icons/${item.icon}.svg`}
        alt={item.title}
        style={{
          display: activeStyles.show,
          width: ImgSize,
          height: ImgSize
        }}
      />
      <animated.img
        src={`/icons/${item.iconActive}.svg`}
        alt={item.title}
        style={{
          display: activeStyles.hide,
          width: ImgSize,
          height: ImgSize
        }}
      />
      <animated.p
        className="absolute left-50% top-110% transform -translate-x-50% transition-opacity duration-300 ease-in-out opacity-0 group-hover:!opacity-100 text-dark-blue font-700 text-12px font-Montserrat"
        style={{
          opacity: activeStyles.opacity
        }}
      >
        {item.title}
      </animated.p>
      <animated.p
        className="absolute left-50% top-120% transform -translate-x-50% transition-opacity duration300 ease-in-out opacity-0 text-dark-blue font-700 text-20px font-Montserrat"
        id="dote"
        style={{
          opacity: activeStyles.opacity
        }}
      >
        •
      </animated.p>
    </animated.a>
  )
}

export function StaticDockCard({ item }: { item: NavItem }) {
  const pathname = globalThis?.window?.location?.pathname
  const isHomeCard = item.href === '/'
  const isActive = isHomeCard ? pathname === '/' : pathname?.includes(item.href)

  const background = isActive
        ? 'linear-gradient(142.9deg, #2f4efa -30.01%, #8fffab 113.8%)'
        : 'white'

  const containerSize = isActive ? 54 : 48
  const imageSize = isActive ? 35 : 30
  const opacity = isActive ? '1' : '0'
  return (
    <a
      href={item.href}
      className={` group relative bg-hex-fff rounded-13px shadow w-53px h-53px flex justify-center items-center <sm:rounded-8px 
      `}
      id={item.href}
      style={{
        background,
        width: containerSize,
        height: containerSize,
        // y
      }}
    >
      {/* FIXME: weird issue on remove below p tag */}
      <p className="hidden">
        {isActive
          ? 'true'
          : 'false'}
      </p>

      <img
        src={`/icons/${item.icon}.svg`}
        alt={item.title}
        style={{
          display: isActive ? 'none' : 'block',
          width: imageSize,
          height: imageSize
        }}
      />
      <img
        src={`/icons/${item.iconActive}.svg`}
        alt={item.title}
        style={{
          display: isActive ? 'block' : 'none',
          width: imageSize,
          height: imageSize
        }}
      />
      <p
        className="absolute left-50% top-110% transform -translate-x-50% transition-opacity duration-300 ease-in-out opacity-0 group-hover:!opacity-100 text-dark-blue font-700 text-12px font-Montserrat"
        style={{
          opacity
        }}
      >
        {item.title}
      </p>
      <p
        className="absolute left-50% top-120% transform -translate-x-50% transition-opacity duration300 ease-in-out opacity-0 text-dark-blue font-700 text-20px font-Montserrat"
        id="dote"
        style={{
          opacity
        }}
      >
        •
      </p>
    </a>
  )
}
