import { navItems } from '@utils/constants'
import type { NavItem } from '@utils/constants'
import { Dock, StaticDock, } from './Dock'
import { DockCard, StaticDockCard } from './Dock/DockCard'

const hasHover = !globalThis.window?.matchMedia('(hover: none)').matches

export default function Navbar() {
  if (hasHover) {
    return (
      <Dock>
        {navItems.map((item: NavItem) => (
          <DockCard key={item.id} item={item} />
        ))}
      </Dock>
    )
  }
  return (
    <StaticDock>
      {navItems.map((item: NavItem) => (
        <StaticDockCard key={item.id} item={item} />
      ))}
    </StaticDock>
  )
}
